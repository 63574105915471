import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { OutboundLink } from "gatsby-plugin-gtag"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BookPostTemplate = ({ data }) => {
  const book = data.markdownRemark
  const { title, subtitle, cover, isbn, price } = book.frontmatter
  const fullTitle = title + (subtitle ? `: ${subtitle}` : "")
  return (
    <Layout>
      <SEO title={fullTitle} />
      <article className="measure center">
        {cover?.childImageSharp?.fluid && (
          <Img
            fluid={cover.childImageSharp.fluid}
            alt={`Cover for <em>${fullTitle}</em>`}
          />
        )}
        <hgroup className="flex flex-column">
          <h1>{title}</h1>
          {subtitle && <h2>{subtitle}</h2>}
          <div className="sans-serif flex flex-row justify-between">
            {isbn && <h3 className="f4">{`ISBN: ${isbn}`}</h3>}
            {price && (
              <h3 className="f4">
                <abbr title="suggested retail price">SRP</abbr>
                {`: $${price}`}
              </h3>
            )}
          </div>
        </hgroup>
        <section dangerouslySetInnerHTML={{ __html: book.html }} />
        <hr />
        <OutboundLink
          class="link dim br2 ba bw1 ph3 pv2 mb2 dib black"
          href={book.frontmatter.outboundLink}
        >
          Buy the book
        </OutboundLink>
      </article>
    </Layout>
  )
}
export default BookPostTemplate

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        subtitle
        cover {
          publicURL
          ...fluidImage
        }
        price
        isbn
        outboundLink
      }
    }
  }
`
